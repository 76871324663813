import type { IClient } from "@/models/clients";
import type { IUser } from "@/models/users";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useAuth } from "@/hooks/auth";

export const useClients = () => {
  const route = useRoute();
  const { getters, dispatch } = useStore();

  const {
    isClientAdmin,
    isClientUnderwriter,
    isClientAnalyst,
    isClientFundingAdvisor
  } = useAuth();

  const isClientSettings = route.name === "ClientSettings";

  const activeClient = computed<IClient | null>(
    () => getters["clients/active"]
  );

  const authClientSettings = computed<IClient | null>(
    () => getters["auth/authClientSettings"]
  );

  const isSelfFunding = computed(
    () =>
      authClientSettings.value?.can_create_underwriter &&
      authClientSettings.value?.has_linked_funder
  );

  const promoteToOwner = (userId: number | null | undefined) => {
    if (!userId || !activeClient.value?.id) {
      return;
    }
    const payload = {
      clientId: activeClient.value.id,
      userId
    };
    dispatch("clients/promoteToOwner", payload);
  };

  const isClientOwner = (user: IUser | null | undefined) => {
    if (!isClientSettings) {
      return false;
    }
    return activeClient.value?.owner?.id === user?.id;
  };

  const clientCanDoUnderwriting = computed(() => {
    const clientCanCreateUnderwriter =
      !!authClientSettings.value?.can_create_underwriter;
    return (
      (isClientAdmin ||
        isClientUnderwriter ||
        isClientAnalyst ||
        isClientFundingAdvisor) &&
      clientCanCreateUnderwriter
    );
  });

  return {
    isClientOwner,
    activeClient,
    promoteToOwner,
    isSelfFunding,
    authClientSettings,
    clientCanDoUnderwriting
  };
};
