<template>
  <lf-card class="max-w-screen-md">
    <loader :is-loading="isSubmitting" />
    <form @submit.prevent="submit">
      <div class="pb-2 pt-8 space-y-5">
        <lf-switch
          name="data.can_manage_attribute_builder"
          v-model="initialValues.data.can_manage_attribute_builder"
        >
          <lf-h3>
            {{ $t("ORGANIZATION.ENABLE_ATTRIBUTE_BUILDER") }}
          </lf-h3>
          <span class="mt-2">
            {{ $t("ORGANIZATION.ENABLE_ATTRIBUTE_BUILDER_SUBTITLE") }}
          </span>
        </lf-switch>
      </div>
      <div
        class="flex justify-end items-center min-w-full border-t py-6 pr-5 mt-5"
      >
        <primary-button type="submit" :disabled="isSubmitting">
          {{ $t("ORGANIZATION.API_CONFIG.SAVE_CHANGES") }}
        </primary-button>
      </div>
    </form>
  </lf-card>
</template>

<script lang="ts" setup>
import { reactive } from "vue";
import { useForm } from "vee-validate";
import { dispatchAction } from "@/helpers/vee-validate";
import { useClients } from "@/hooks/clients";
import { useNotification } from "@/hooks/notifications";
import { useI18n } from "vue-i18n";

const { activeClient } = useClients();
const { showMessage } = useNotification();
const { t } = useI18n();

const initialValues = reactive({
  data: {
    can_manage_attribute_builder:
      activeClient.value?.can_manage_attribute_builder || false
  }
});

const { handleSubmit, isSubmitting } = useForm({
  initialValues
});

const submit = handleSubmit(async (values, actions) => {
  try {
    await dispatchAction(values, actions, "clients/updateClient");
  } catch {
    showMessage(t("COMMON.ERROR_OCCURRED"), "error");
  }
});
</script>
